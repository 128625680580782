import { useEffect } from 'react';
import 'pages/Home/PhotoSharePage/PhotoShare.css';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';

function PhotoShare() {
    useEffect(() => {
        const photoShareLine = document.getElementById('photo_share_line');
        if (photoShareLine) {
            photoShareLine.addEventListener('click', handleLineClick);
        }
        return () => {
            if (photoShareLine) {
                photoShareLine.removeEventListener('click', handleLineClick);
            }
        };
    }, []);

    const handleLineClick = () => {
        // LINEアプリを開くためのURLスキーム
        const lineUrl = 'https://lin.ee/do2I2GW';

        // LINEアプリがインストールされているかどうかをチェック
        const appInstalled = window.navigator.userAgent.toLowerCase().indexOf('line') > -1;

        // LINEアプリがインストールされていればアプリを開く
        if (appInstalled) {
            window.location.href = lineUrl;
        } else {
            // インストールされていない場合はLINEのWebページを開くなどの代替処理を行う
            window.open('https://line.me/');
            // alert('LINEアプリがインストールされていません');
        }
    };

    return (
        <div id="photo_share_page">
            <Header />
            <Menu />

            <div id='photo_share_titles'>
                <header className='Title' id='photo_share_header'>Photo Share</header>
                <header className='SubTitle' id='photo_share_subheader'>写真共有</header>
            </div>

            <div id='photo_share_top_img'>
                <img src='/images/photo_share2.png' className='Image' alt="" />
            </div>

            <div id='photo_share_content'>
                <div id='photo_share_content_sentence'>
                    <p>本日撮影いただいたお写真・動画を</p>
                    <p>公式ラインまたは新郎新婦どちらかのLINEへ</p>
                    <p>共有いただけると大変嬉しいです</p>
                    <p>新郎新婦の写っていないお写真も</p>
                    <p>是非拝見したいです</p>
                    <p><br></br></p>
                    <p>新郎新婦が見られない視点もありますので</p>
                    <p>ゲストの皆様のお写真</p>
                    <p>会場・お料理のお写真</p>
                    <p>どのようなお写真でも大歓迎です</p>
                    <p>ありとあらゆるお写真をぜひ共有してください :)</p>
                </div>

                <div onClick={handleLineClick} id='photo_share_line'>公式LINEはこちらから</div>

                <img src='/images/background-1.JPEG' id='photo_share_content_bg' alt="" />
            </div>

            <Footer />
        </div>
    );
}

export default PhotoShare;
