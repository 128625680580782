import 'features/Home/Top/Body/Information/Information.css';
import Button from 'features/Home/Top/Body/Information/Button/Button';

function Information() {
    const seating_chart_param = {
        'title': '席次表',
        'sub_title': 'Seating Chart',
        'image': '/images/icon/table.jpg',
        'uri': '/seat',
    };
    const drink_menu_param = {
        'title': 'ドリンクメニュー',
        'sub_title': 'Drink Menu',
        'image': '/images/icon/drink.jpg',
        'uri': '/drink-menu',
    };
    const menu_param = {
        'title': 'お料理',
        'sub_title': 'Menu',
        'image': '/images/icon/dish.jpg',
        'uri': '/menu',
    };
    const share_photo_param = {
        'title': '写真共有',
        'sub_title': 'Share Photo',
        'image': '/images/icon/camera.jpg',
        'uri': '/photo-share',
    };

    return (
        <div id="information">
            <p className='Title'>Information</p>

            <div id='information_buttons'>
                {/* <Button params={seating_chart_param} /> */}
                <Button params={drink_menu_param} />
                <Button params={menu_param} />
                {/* <Button params={share_photo_param} /> */}
            </div>
        </div>
    );
}

export default Information;
