import Background from 'features/Home/Top/Background/Background';
import Body from 'features/Home/Top/Body/Body';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';
import 'pages/Home/TopPage/Top.css';

function Top() {
    return (
        <div id="top_page">
            <Header />
            <Menu />
            
            <Body />
        </div>
    );
}

export default Top;
