import React, { useState } from 'react';

import Button from 'components/Menu/Button/Button';
import Body   from 'components/Menu/Body/Body';

import 'components/Menu/Menu.css';

function Menu({ mode }) {
    // メニューボタンの押下、または表示中に背景押下でメニュー本体の表示切替
    const [isBodyVisible, setIsBodyVisible] = useState(false);
    const toggleBodyVisibility = () => {
        setIsBodyVisible(!isBodyVisible);
    };

    return (
        <div id='menu'>
            <Button onClick={toggleBodyVisibility} isOpen={isBodyVisible} />

            {/* 表示フラグがtrueであれば表示 */}
            {isBodyVisible &&
                <div id='menu_wrapper'>
                    <div id='menu_background' onClick={toggleBodyVisibility} className={{ isBodyVisible } ? 'visible' : ''}></div>
                    <Body isVisible={isBodyVisible} mode={mode} />
                </div>
            }
        </div>
    );
}

export default Menu;
