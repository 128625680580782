import 'features/Home/SeatingChart/Button/Button.css';

function Button({ handleClick, table_index }) {
    const handleButtonClick = () => {
        handleClick(table_index);
    };

    return (
        <div id="seat_chart_button">
            <p onClick={handleButtonClick}>{table_index}</p>
        </div>
    );
}

export default Button;
