import { useNavigate } from 'react-router-dom';
import 'pages/Home/DishPage/Dish.css';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';

function Dish() {
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        // ホームに遷移
        navigate('/');
    };
    return (
        <div id="dish_page">
            <Header />
            <Menu />

            <div id='dish_body' className='main'>
                <div id='breadcrumbs'>
                    <div className='' id='profile_header' onClick={handleBackButtonClick}>＜ 戻る</div>
                </div>

                <div id='dish_content'>
                    <p className='Title DrinkTitle'>Menu</p>
                    <div className='dish_wrapper'>
                        <div id='beer'>
                            <br/>
                            <p className='dish_category'>ジュエルフード</p>
                            <br/>
                            <br/>
                            
                            <p className='dish_category'>海と山の恵みのオードブル</p>
                            <br/>
                            <br/>
                            
                            <p className='dish_category'>オニオンスープ</p>
                            <br/>
                            <br/>
                            
                            <p className='dish_category'>オマール海老　野菜風味のパネ</p>
                            <p className='dish_subcategory'>パルマンティエのジャガイモ添え</p>
                            <br/>
                            <br/>

                            <p className='dish_category'>特選牛フィレ肉のロースト</p>
                            <p className='dish_subcategory'>厳選野菜のジャルダン風</p>
                            <br/>
                            <br/>
                            
                            <p className='dish_category'>鯛のお茶漬け</p>
                            <p className='dish_subcategory'>聖ペトロ教会オリジナル</p>
                            <br/>
                            <br/>

                            <p className='dish_category'>焼きたて自家製パン</p>
                            <br/>
                            <br/>

                            <p className='dish_category'>ウェディングケーキ</p>
                            <br/>
                            <br/>
                            
                            <p className='dish_category'>デザート盛り合わせ</p>
                            <p className='dish_subcategory'>ガトーショコラ　チーズケーキ　フルーツジュレ</p>
                            <br/>
                            <br/>

                            <p className='dish_category'>コーヒー又は紅茶</p>

                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <Footer />
        </div>
    );
}

export default Dish;
