import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEAT_INFO } from 'configs/constants'
import 'pages/Home/SeatingChartPage/SeatingChart.css';
import Button from 'features/Home/SeatingChart/Button/Button';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';

function SeatingChart() {
    const [table_index, setTableIndex] = useState(null);

    // Buutonコンポーネントがクリックされたときに呼び出されるコールバック関数
    const handleChildClick = (newTableIndex) => {
        setTableIndex(newTableIndex);
    };

    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        // ホームに遷移
        navigate('/');
    };

    return (
        <div id="seat_page">
            <Header />
            <Menu />

            <div id='seat_body'>
                <div id='breadcrumbs'>
                    <div className='' id='seatingchart_header' onClick={handleBackButtonClick}>＜ 戻る</div>
                </div>

                <div id='seat_title'>
                    <p className='SubTitle' id='seat_location'>南蔵王　聖ペトロ教会に於いて</p>
                </div>

                <div id='seat_detail'>
                    {
                        table_index === null ? (
                            <div id='seat_detail_default'>
                                <p>テーブル席を選択すると</p>
                                <p>こちらに出席者が表示されます</p>
                            </div>
                        ) : (
                            <div id='seat_detail_selected'>
                                <div className='seat_table_colm left'>
                                    {SEAT_INFO[table_index].member1.map((item, index) => (
                                        <div className='seat_table_item'>
                                            <p>{item.slogan}</p>
                                            <div className='seat_table_name'>
                                                <p className='name'>{item.name}</p>
                                                <p className='name_title'>様</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='seat_table_explan'>
                                    <p className='seat_table_side'>{SEAT_INFO[table_index].side}</p>
                                    <p className='seat_table_circle'>{table_index}</p>
                                    <p className='seat_table_relationship'>{SEAT_INFO[table_index].relation}</p>
                                </div>
                                <div className='seat_table_colm right'>
                                    {SEAT_INFO[table_index].member2.map((item, index) => (
                                        <div className='seat_table_item'>
                                            <p>{item.slogan}</p>
                                            <div className='seat_table_name'>
                                                <p className='name'>{item.name}</p>
                                                <p className='name_title'>様</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </div>


                <div id='seat_graph_wrapper'>
                    <div id='seat_graph_main'>Yuya　Asuka</div>
                    <div className='seat_graph_colm' id='seat_graph_row1'>
                        <Button handleClick={handleChildClick} table_index='A' />
                        <Button handleClick={handleChildClick} table_index='B' />
                        <Button handleClick={handleChildClick} table_index='C' />
                        <Button handleClick={handleChildClick} table_index='D' />
                    </div>
                    <div className='seat_graph_colm' id='seat_graph_row2'>
                        <div className='left'>
                            <Button handleClick={handleChildClick} table_index='E' />
                        </div>
                        <Button handleClick={handleChildClick} table_index='F' />
                        <Button handleClick={handleChildClick} table_index='G' />
                    </div>
                    <div className='seat_graph_colm' id='seat_graph_row3'>
                        <Button handleClick={handleChildClick} table_index='H' />
                        <Button handleClick={handleChildClick} table_index='I' />
                        <Button handleClick={handleChildClick} table_index='J' />
                        <Button handleClick={handleChildClick} table_index='K' />
                    </div>
                </div>

                <div id='seat_attention'>
                    <p>席順　ご芳名に失礼な点がございましたら</p>
                    <p>慶事に免じご寛容の程お願い申し上げます</p>
                </div>
            </div>

            <Footer />
        </div >
    );
}

export default SeatingChart;
