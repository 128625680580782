import 'features/Home/Top/Body/Greeting/Greeting.css';

function Greeting() {
    return (
        <div id="greeting">
            <p className='Title'>Greeting</p>

            <div className='Sentence' id='greeting_sentence'>
                <p>本日はご多用のところ　お越しくださり</p>
                <p>誠にありがとうございます</p>
                <p><br></br></p>
                <p>ここにお集まりいただいた皆様は</p>
                <p>わたしたちにとって　大切な方たちばかりです</p>
                <p>皆様に見守られて　この日を迎えることができ</p>
                <p>わたしたちはとても幸せです</p>
                <p><br></br></p>
                <p>未熟なふたりですが　力を合わせて</p>
                <p>笑顔溢れる家庭を築いていきます</p>
                <p>これからも　わたしたちをよろしくお願いいたします</p>
            </div>
        </div>
    );
}

export default Greeting;
