import { useNavigate } from 'react-router-dom';

import 'features/Home/Top/Body/Information/Button/Button.css';

function Button({ params }) {
    const navigate = useNavigate();

    const handleClick = () => {
        // 指定したURIに遷移する
        navigate(params.uri);
    };

    return (
        <div className='info_button_wrapper'>
            <img src={params.image} className='info_button_image' onClick={handleClick} alt="" />
            <div className='info_button_overlay'>
                <p className='info_button_title'>{params.title}</p>
                <p className='info_button_sub_title'>{params.sub_title}</p>
            </div>
        </div>
    );
}

export default Button;
