import { useNavigate } from 'react-router-dom';
import 'pages/Home/DrinkMenuPage/DrinkMenu.css';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';
import Footer from 'components/Footer/Footer';

function DrinkMenu() {
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        // ホームに遷移
        navigate('/');
    };

    return (
        <div id="drink_menu_page">
            <Header />
            <Menu />

            <div id='drink_menu_body' className='main'>
                <div id='breadcrumbs'>
                    <div className='' id='profile_header' onClick={handleBackButtonClick}>＜ 戻る</div>
                </div>

                <div id='drink_menu_content'>
                    <p className='Title DrinkTitle'>ALCOHOL</p>
                    <div className=''>
                        <div className='drink_menu_category' id='non_alc_beer'>
                            <p className='drink_menu_category'>スパークリングワイン</p>
                            <p className='drink_menu_category'>ビール</p>
                            <p className='drink_menu_category'>ウィスキー</p>
                            <p className='drink_menu_category'>赤ワイン</p>
                            <p className='drink_menu_category'>白ワイン</p>
                            <p className='drink_menu_category'>日本酒</p>
                            <p className='drink_menu_category'>芋焼酎</p>
                            <p className='drink_menu_category'>麦焼酎</p>
                        </div>
                    </div>  

                    <p className='Title DrinkTitle'>NON ALCOHOL</p>
                    <div className=''>
                        <div className='drink_menu_category' id='non_alc_beer'>
                            <p className='drink_menu_category'>ノンアルコールビール</p>
                            <p className='drink_menu_category'>ウーロン茶</p>
                            <p className='drink_menu_category'>オレンジジュース</p>
                            <p className='drink_menu_category'>リンゴジュース</p>
                            <p className='drink_menu_category'>コーラ</p>
                            <p className='drink_menu_category'>グレープフルーツジュース</p>
                            <p className='drink_menu_category'>ジンジャーエール</p>
                            <p className='drink_menu_category'>炭酸水</p>
                        </div>
                    </div>

                    <p className='Title DrinkTitle'>Original Cocktail</p>
                    <div className=''>
                        <div className='drink_menu_category' id='non_alc_beer'>
                            <p className='drink_menu_category'>ディタ</p>
                            <p className='drink_menu_category'>ジン</p>
                            <p className='drink_menu_category'>カシス</p>
                            <p className='drink_menu_category'>パッションフルーツ</p>
                            <p className='drink_menu_category'>ブルーキュラソウ</p>
                            <p className='drink_menu_category'>ストロベリー</p>
                            <p className='drink_menu_category'>グリーンアップル</p>
                            <p className='drink_menu_category'>ピーチ</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>

            <Footer />
        </div>
    );
}

export default DrinkMenu;
