import 'components/Footer/Footer.css';

function Footer({ mode }) {
    return (
        <footer id="fotter">

            <p className='footer_menu'>thank you for coming today!</p>
        </footer>
    );

}

export default Footer;
