import { useNavigate } from 'react-router-dom';

import Title from 'components/Title/Title';
import Menu  from 'components/Menu/Menu'

import 'components/Header/Header.css';

function Header({ mode }) {
    return (
        <header id="header">
            <Title mode={mode} />
        </header>
    );
}

export default Header;
