import 'features/Home/Top/Body/Timeline/Timeline.css';

function Timeline() {
    return (
        <div id="timeline">
            <p className='Title'>Time Schedule</p>
            <p className='SubTitle'>進行について</p>

            <img src='/images/timeline.png' className='Image' alt="" />
        </div>
    );
}

export default Timeline;
