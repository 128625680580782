import { useNavigate } from 'react-router-dom';
import 'features/Home/Top/Body/Profile/Profile.css';

function Profile() {
    const navigate = useNavigate();

    const handleClick = () => {
        // 指定したURIに遷移する
        navigate('/profile');
    };

    return (
        <div id="profile">
            <p className='Title'>Profile</p>

            <div id='profile_image_wrapper'>
                <img src='/images/yousou/IMG_3690.JPG' id='profile_image' alt="" />
            </div>

            <div className='Sentence' id='profile_sentence'>
                <p>出身地　好きなもの　お互いのことについて　etc...</p>
            </div>

            <div id='read_more' onClick={handleClick}>
                <div id='read_more_text'>
                    More
                </div>
            </div>
        </div>
    );
}

export default Profile;
