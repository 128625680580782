import TopStyle from 'features/Home/Top/Body/Top/Top.module.css';

function Top() {
    return (
        <div id="top">
            <div className='Image'>
                <img src='/images/yousou/IMG_3682.JPG' className='Image' alt="" />
            </div>

            {/* <div className='scroll'>
                <div className='scroll_line'>
                    <div id="wrapper">
                        <div id="wrapper-inner">
                            <div id="scroll-down"></div>
                        </div>
                    </div>
                </div>
                <div className='scroll_text'>scroll</div>
            </div> */}
        </div>
    );
}

export default Top;
