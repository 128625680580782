import Top from 'features/Home/Top/Body/Top/Top'
import Greeting from 'features/Home/Top/Body/Greeting/Greeting'
import Profile from 'features/Home/Top/Body/Profile/Profile'
import Photo from 'features/Home/Top/Body/Photo/Photo'
import Information from 'features/Home/Top/Body/Information/Information'
import Timeline from 'features/Home/Top/Body/Timeline/Timeline'
import Footer from 'components/Footer/Footer';
import 'features/Home/Top/Body/Body.css';

function Body() {
    return (
        <div className="Body">
            <Top />
            <Greeting />
            <Profile />
            <Information />
            <Timeline />
            <Photo />
            <Footer />
        </div>
    );
}

export default Body;
