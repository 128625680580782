import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Top          from 'pages/Home/TopPage/Top';
import DrinkMenu    from 'pages/Home/DrinkMenuPage/DrinkMenu';
import Dish         from 'pages/Home/DishPage/Dish';
import SeatingChart from 'pages/Home/SeatingChartPage/SeatingChart';
import PhotoShare   from 'pages/Home/PhotoSharePage/PhotoShare';
import Profile      from 'pages/Home/ProfilePage/Profile';

import 'components/App/App.css';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <div>
                <ScrollToTop />
                <Routes>
                    {/* 
                        メインサイト
                            Top
                            プロフィール
                            席次表
                            ドリンクメニュー
                            メニュー
                            写真共有
                    */}
                    <Route exact path="/" element={<Top />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/seat" element={<SeatingChart />} />
                    <Route exact path="/drink-menu" element={<DrinkMenu />} />
                    <Route exact path="/menu" element={<Dish />} />
                    <Route exact path="/photo-share" element={<PhotoShare />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
