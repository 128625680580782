import 'features/Home/Top/Body/Photo/Photo.css';

function Photo() {
    return (
        <div id="photo">
            <img src='/images/wasou/293740_155.JPG' id='photo_image1' alt="" />
            <img src='/images/wasou/293740_031.JPG' id='photo_image2' alt="" />
            <img src='/images/wasou/293740_006.JPG' id='photo_image3' alt="" />
            <img src='/images/wasou/293740_057.JPG' id='photo_image4' alt="" />
        </div>
    );
}

export default Photo;
