import React from 'react';
import { Link } from 'react-router-dom';
import 'components/Menu/Body/Body.css';

function Body({ isVisible, mode }) {
    return (
        <div id="menu_body" className={isVisible ? 'visible' : ''}>
            <div id='menu_index'>
                <Link to="/profile" className="menu_link"><p>Our Profile</p></Link>
                <p>Wedding Information</p>
                <div id='information_index'>
                    {/* <Link to="/seat" className="menu_link"><p>Seating Seat</p></Link> */}
                    <Link to="/drink-menu" className="menu_link"><p>Drink Menu</p></Link>
                    <Link to="/menu" className="menu_link"><p>Menu</p></Link>
                    {/* <Link to="/photo-share" className="menu_link"><p>Share Photo</p></Link> */}
                </div>
            </div>
            {/* <div id='menu_contact'>
                Contact Us
            </div> */}
            <div id='menu_logo_title'></div>
        </div>
    );
}

export default Body;
