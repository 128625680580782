import 'components/Menu/Button/Button.css';

function Button({ onClick, isOpen }) {
    return (
        <div id='menu_button_wrapper' onClick={onClick}>
            <div className={`burger burger-squeeze ${isOpen ? 'open' : ''}`} id='menu_button'>
                <div className="burger-lines"></div>
            </div>
        </div>
    );
}

export default Button;
