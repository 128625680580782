import { useNavigate } from 'react-router-dom';
import 'components/Title/Title.css';

function Title({ mode }) {
    // クリックイベント
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
    };

    // 表示テキスト切り替え
    let titleText;
    switch (mode) {
        case 'wedding':
            titleText = "Yuya & Asuka WD";
            break;
        case 'invitation':
            titleText = "Yuya & Asuka WD Invitation";
            break;
        default:
            titleText = "Yuya & Asuka WD";
    }

    // View
    return (
        <div id="title" onClick={handleClick}>
            {titleText}
        </div>
    );
}

export default Title;
