import { useNavigate } from 'react-router-dom';

import 'pages/Home/ProfilePage/Profile.css';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';

function Profile() {
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        // ホームに遷移
        navigate('/');
    };

    return (
        <div id="profile_page">
            <Header />
            <Menu />
            <div className='main'>
                <div id='breadcrumbs'>
                    <div className='' id='profile_header' onClick={handleBackButtonClick}>＜ 戻る</div>
                </div>

                <div id='profile_top_img'>
                    <img src='/images/yousou/maedori-0383.jpg' className='Image' alt="" />
                </div>

                <div className='profile_content'>
                    <div className='Title'>Kawahara Yuya</div>
                    <div className='SubTitle'>川原 祐哉</div>

                    <br />
                    <div className='SubTitle'>Profile</div>
                    <div>
                        <table className='profile_table'>
                            <tr>
                                <td className='td_first'>誕生日</td>
                                <td>1987年9月30日</td>
                            </tr>
                            <tr>
                                <td className='td_first'>血液型</td>
                                <td>O型</td>
                            </tr>
                            <tr>
                                <td className='td_first'>出身地</td>
                                <td>広島県尾道市</td>
                            </tr>
                            <tr>
                                <td className='td_first'>趣味</td>
                                <td>ゲーム　ギター</td>
                            </tr>
                            <tr>
                                <td className='td_first'>好きな食べ物</td>
                                <td>秋刀魚の塩焼き</td>
                            </tr>
                            <tr>
                                <td className='td_first'>きらいな食べ物</td>
                                <td>ミョウガ</td>
                            </tr>
                        </table>
                    </div>

                    <div id='profile_favorite_groom'>
                        <img src='/images/icon/fugu.JPEG' className='Image' alt="" />
                        <div className='favorite_content'>
                            <p className='favorite_subtitle'>Aquarium</p>
                            <div className='favorite_explan'>
                                <p>- 家で飼育している</p>
                                <p>- アベニーパファー</p>
                                <p>- 食欲旺盛だけど寝起きは機嫌が悪い</p>
                            </div>
                        </div>
                    </div>

                    <div id='profile_favorite_groom'>
                        <img src='/images/icon/anime.jpg' className='Image' alt="" />
                        <div className='favorite_content'>
                            <p className='favorite_subtitle'>Anime</p>
                            <div className='favorite_explan'>
                                <p>- 夕食時は必ず2人でアニメを観る</p>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <br />
                <br />

                <div id='profile_top_img'>
                    <img src='/images/yousou/IMG_3684.JPG' className='Image' alt="" />
                </div>

                <div className='profile_content'>
                    <div className='Title'>Gomi Asuka</div>
                    <div className='SubTitle'>五味 明日香</div>

                    <br />
                    <div className='SubTitle'>Profile</div>
                    <div>
                        <table className='profile_table'>
                            <tr>
                                <td className='td_first'>誕生日</td>
                                <td>1998年2月14日</td>
                            </tr>
                            <tr>
                                <td className='td_first'>血液型</td>
                                <td>A型</td>
                            </tr>
                            <tr>
                                <td className='td_first'>出身地</td>
                                <td>広島県福山市</td>
                            </tr>
                            <tr>
                                <td className='td_first'>趣味</td>
                                <td>ゲーム　スノボー</td>
                            </tr>
                            <tr>
                                <td className='td_first'>好きな食べ物</td>
                                <td>アジ</td>
                            </tr>
                            <tr>
                                <td className='td_first'>きらいな食べ物</td>
                                <td>餅</td>
                            </tr>
                        </table>
                    </div>

                    <div id='profile_favorite_groom'>
                        <img src='/images/icon/kirby.jpg' className='Image' alt="" />
                        <div className='favorite_content'>
                            <p className='favorite_subtitle'>Kirby</p>
                            <div className='favorite_explan'>
                                <p>- グッズ集めが趣味</p>
                                <p>- ワドルディが一番好き</p>
                                <p>- 定期的に新郎と2人でカービィカフェへ行く</p>
                            </div>
                        </div>
                    </div>

                    <div id='profile_favorite_groom'>
                        <img src='/images/icon/cake.jpg' className='Image' alt="" />
                        <div className='favorite_content'>
                            <p className='favorite_subtitle'>Dessert</p>
                            <div className='favorite_explan'>
                                <p>- 毎週デザートを買う</p>
                                <p>- アイスもケーキも好き</p>
                                <p>- 結婚式で解禁なので今日は思い切り食べたい</p>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <br />
                <br />

                <div id='profile_top_img'>
                    <img src='/images/wasou/293740_065.JPG' className='Image' alt="" />
                </div>

                <div className='profile_content'>
                    <div className='Title'>Q & A</div>

                    <div id='qa'>
                        <div id='qa_items'>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>01</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>お互いの呼び方は？</p>
                                        <p>新郎：あすか</p>
                                        <p>新婦：ゆうさん</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>02</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>お互いの第一印象は？</p>
                                        <p>新郎：勉強してくれますように</p>
                                        <p>新婦：顔が好み過ぎる</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>03</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>相手の好きなところは？</p>
                                        <p>新郎：安心できるところ</p>
                                        <p>新婦：圧倒的な心の広さ</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>04</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>相手に直してほしいところは？</p>
                                        <p>新郎：食べ物の好き嫌い</p>
                                        <p>新婦：服装に無頓着なところ</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>05</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>結婚の決め手は？</p>
                                        <p>新郎：着飾らなくていい安心感</p>
                                        <p>新婦：引っ張ってくれるという安心感</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qa_item'>
                                <div className='qa_nums'>
                                    <p className='qa_no'>No.</p>
                                    <p className='qa_num'>06</p>
                                </div>
                                <div className='qa_content'>
                                    <p>-</p>
                                    <div className='qa_body'>
                                        <p>どんな夫婦になりたい？</p>
                                        <p>新郎：名前で呼び合える夫婦</p>
                                        <p>新婦：おじいちゃんとおばあちゃんになっても手を繋げる夫婦</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />  
                <div className='profile_content'>
                    <div className='Title'>Our History</div>

                    <br />

                    <div id='profile_favorite_groom'>
                        <div className='favorite_content'>
                            <div className='favorite_explan'>
                                <p>- 2013.7 出会い -</p>
                                <p>塾の夏期講習がきっかけ</p>
                            </div>
                        </div>
                        <img src='/images/yousou/maedori-0197-2.jpg' className='Image' alt="" />
                    </div>
                    <div id='profile_favorite_groom'>
                        <div className='favorite_content'>
                            <div className='favorite_explan'>
                                <p>- 2016.5.22 交際記念日</p>
                                <p>尾道デートで告白</p>
                            </div>
                        </div>
                        <img src='/images/wasou/293740_125_1.JPG' className='Image' alt="" />
                    </div>
                    <div id='profile_favorite_groom'>
                        <div className='favorite_content'>
                            <div className='favorite_explan'>
                                <p>- 2020.4 同棲開始 -</p>
                                <p>神奈川で2人の生活</p>
                            </div>
                        </div>
                        <img src='/images/yousou/maedori-0405.jpg' className='Image' alt="" />
                    </div>
                    <div id='profile_favorite_groom'>
                        <div className='favorite_content'>
                            <div className='favorite_explan'>
                                <p>- 2024.5.22 入籍 -</p>
                                <p>交際8年記念日</p>
                            </div>
                        </div>
                        <img src='/images/wasou/293740_026_1.JPG' className='Image' alt="" />
                    </div>
                    <div id='profile_favorite_groom'>
                        <div className='favorite_content'>
                            <div className='favorite_explan'>
                                <p>- 2024.11.4 挙式 -</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Profile;
