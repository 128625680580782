export const SEAT_INFO = {
    'A': {
        'side': '新郎',
        'relation': '高校友人',
        'member1': [
            { 'slogan': '通年ダイエット中', 'name': '佐藤　隆' },
            { 'slogan': 'となりのトトロ', 'name': '高橋　誠一郎' },
            { 'slogan': '通年ダイエット中', 'name': '佐藤　隆' },
            { 'slogan': 'となりのトトロ', 'name': '高橋　誠一郎' }
        ],
        'member2': [
            { 'slogan': 'となりのトトロ', 'name': '高橋　誠一郎' },
            { 'slogan': '通年ダイエット中', 'name': '佐藤　隆' },
            { 'slogan': 'となりのトトロ', 'name': '高橋　誠一郎' },
            { 'slogan': '通年ダイエット中', 'name': '佐藤　隆' }
        ]
    },
    'B': {
        'side': '新郎',
        'relation': '大学友人',
        'member1': [
            { 'slogan': 'サムライビジネスマン', 'name': '鈴木　太郎' },
            { 'slogan': '寿司愛好家', 'name': '山田　花子' },
            { 'slogan': 'サムライビジネスマン', 'name': '鈴木　太郎' },
            { 'slogan': '寿司愛好家', 'name': '山田　花子' }
        ],
        'member2': [
            { 'slogan': '寿司愛好家', 'name': '山田　花子' },
            { 'slogan': 'サムライビジネスマン', 'name': '鈴木　太郎' },
            { 'slogan': '寿司愛好家', 'name': '山田　花子' },
            { 'slogan': 'サムライビジネスマン', 'name': '鈴木　太郎' }
        ]
    },
    'C': {
        'side': '新婦',
        'relation': '高校友人',
        'member1': [
            { 'slogan': 'ラーメン探検家', 'name': '田中　健太' },
            { 'slogan': 'アニメマニア', 'name': '小林　美咲' },
            { 'slogan': 'ラーメン探検家', 'name': '田中　健太' },
            { 'slogan': 'アニメマニア', 'name': '小林　美咲' }
        ],
        'member2': [
            { 'slogan': 'アニメマニア', 'name': '小林　美咲' },
            { 'slogan': 'ラーメン探検家', 'name': '田中　健太' },
            { 'slogan': 'アニメマニア', 'name': '小林　美咲' },
            { 'slogan': 'ラーメン探検家', 'name': '田中　健太' }
        ]
    },
    'D': {
        'side': '新婦',
        'relation': '大学友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'E': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'F': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'G': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'H': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'I': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'J': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
    'K': {
        'side': '新郎',
        'relation': '地元友人',
        'member1': [
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
        ],
        'member2': [
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
            { 'slogan': '和風アーティスト', 'name': '渡辺　真理子' },
            { 'slogan': 'テクノウィザード', 'name': '高橋　悟' },
        ]
    },
};
